var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"responsive-table"},[_c('table',{staticClass:"contract-preparation-table table table-borderless VueTables__table table table-striped table-border-radius m-0",staticStyle:{"border-collapse":"collapse","border-spacing":"0","width":"100% !important"}},[_vm._m(0),_c('tbody',{staticStyle:{"min-height":"5vh","max-height":"40vh!important","overflow":"scroll"},attrs:{"id":"comparison-table"}},[_vm._l((_vm.winners),function(participantGroup,participantIndex){return _c('tr',{key:participantIndex},[_c('td',[_vm._v(_vm._s(participantIndex + 1))]),_c('td',[_vm._v(_vm._s(participantGroup.name))]),_c('td',[_vm._v(_vm._s(participantGroup.phone))]),_c('td',[_vm._v(_vm._s(participantGroup.email))]),_c('td',[_vm._l((participantGroup.won_lots),function(lot,lotIndex){return _c('span',{key:lot.toString() + lotIndex},[_vm._v(" "+_vm._s(lot.is_from_outside ? lot.procurement_plan.cpv_outside.name : lot.procurement_plan.cpv.name)),(lotIndex!==participantGroup.won_lots.length-1)?_c('span',[_vm._v(",")]):_vm._e()])}),_c('br'),(+_vm.currentOrganized.cpv_type === 1)?_c('button',{staticClass:"btn mt-2",class:participantGroup.won_lots[0].winner_lot_specification ? 'btn-primary':'btn-secondary',attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return (() => {
                  _vm.$modal.show('winner-lots-edit', participantGroup)
                }).apply(null, arguments)}}},[_c('span',[_vm._v("Լրացնել տվյալները")])]):_vm._e()],2),_c('td',[_c('button',{staticClass:"btn",class:participantGroup.director ? 'btn-primary':'btn-secondary',attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return (() => {
                  _vm.$modal.show('winner-requirements-modal', participantGroup)
                }).apply(null, arguments)}}},[_c('span',[_vm._v("Լրացնել")])])]),_c('td',[_vm._v(" "+_vm._s(participantGroup.contract_price)+"դր.")]),_c('td',[_c('div',{staticClass:"row align-items-center justify-content-start m-0 p-0"},[(_vm.getIsSigned(participantGroup))?_c('div',{staticClass:"col-12 mb-2"},[_vm._v(" Կնքված է ")]):_vm._e(),_c('div',{staticClass:"col-12 mb-2 text-primary"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return (() => {
                      _vm.$modal.show('new-editor-modal', {
                        action: 'download',
                        fileName: 'contract_offer',
                        participantGroup: participantGroup,
                        editorTitle: 'Պայմանագիր կնքելու առաջարկ'
                      })
                    }).apply(null, arguments)}}},[_c('span',[_vm._v("Պայմանագրի առաջարկ")])])]),_c('div',{staticClass:"col-12 text-primary"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":participantGroup.director==='' || participantGroup.won_lots[0].winner_lot_specification===''},on:{"click":function($event){$event.preventDefault();return (() => {
                      _vm.$modal.show('contract-modal', {index: participantIndex+1, participantGroup, winnersLength: _vm.winners.length})
                    }).apply(null, arguments)}}},[_c('span',[_vm._v("Պայմանագիր")])])])])])])}),_c('infinite-loading',{ref:"comparisonInfiniteLoading",staticClass:"w-100",attrs:{"slot":"append","force-use-infinite-wrapper":"#comparison-table"},on:{"infinite":_vm.infiniteHandler},slot:"append"},[_c('div',{staticClass:"text-big text-primary spinner-border spinner-border-lg m-2",attrs:{"slot":"spinner","role":"status"},slot:"spinner"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"}),_c('div',{attrs:{"slot":"no-more"},slot:"no-more"})])],2)])]),_c('winner-requirements-modal',{on:{"updateComparisonData":_vm.resetData}}),_c('winner-lots-edit-modal',{on:{"updateComparisonData":_vm.resetData}}),_c('contract-request-modal',{on:{"updateComparisonData":_vm.resetData}}),_c('new-editor-modal'),_c('contract-modal'),_c('editor-modal')],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"20px"}},[_vm._v("Հ/Հ")]),_c('th',[_vm._v("Մասնակցի անվանում")]),_c('th',[_vm._v("Մասնակցի հեռախոսահամար")]),_c('th',[_vm._v("Մասնակցի Էլ. հասցե")]),_c('th',[_vm._v("Գնման առարկաներ")]),_c('th',[_vm._v("Մասնակցի վավերապայմաներ")]),_c('th',[_vm._v("Պայմանագրի գումար")]),_c('th',[_vm._v("Փաստաթղթեր")])])])
}]

export { render, staticRenderFns }